


<!-- Pricing -->
<div class="main-banner extra">
    <div class="main-banner-item item-three">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner-shape">
        <div class="shape-9"><img src="assets/img/banner/banner-shape-6.png" alt="image"></div>
        <div class="shape-10"><img src="assets/img/banner/banner-shape-7.png" alt="image"></div>
        <div class="shape-11"><img src="assets/img/banner/banner-shape-8.png" alt="image"></div>
        <div class="shape-12"><img src="assets/img/banner/banner-shape-9.png" alt="image"></div>
        <div class="shape-13"><img src="assets/img/banner/banner-shape-10.png" alt="image"></div>
        <!-- <div class="shape-14"><img src="assets/img/banner/banner-shape-11.png" alt="image"></div> -->
        <div class="shape-10"><img src="assets/img/banner/banner-shape-7.png" alt="image"></div>
    </div>
</div>
<section class="pricing-area pb-70 pt-70">
    <div class="container">
        <div class="section-title">
            <h2 class="titulo-claro">¿Buscando calidad de servicio?</h2>
            <div class="titulo-claro-sub">
                <span style="color: #ececec;            font-weight: 300;            font-size: 24px;            text-transform: none;">
                    Vas a tener atención preferencial las 24hs en días hábiles. <br><b class="megabold">Vos dale play</b>, del resto <b class="megabold">nos encargamos nosotros.</b></span>
            </div>
        </div>

        <div class="tab pricing-tab">
            <div class="row d-flex justify-content-center">
                <div class="col-lg-3 col-md-3 col-6 clickable" (click)="planSelect(3)">
                    <div class="single-features" [ngClass]="{'active': planselect === 3}">
                        <div class="icon">
                            <i class='bx bx-rocket' ></i>
                        </div>
                        <h3><a>FIBRA</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> -->
                        <div class="number">
                            <span>Internet por Fibra Optica</span>
                        </div>
                        <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 clickable" (click)="planSelect(1)">
                    <div class="single-features" [ngClass]="{'active': planselect === 1}">
                        <div class="icon">
                            <i class='bx bx-wifi'></i>
                            <!-- <i class="flaticon-promotion"></i> -->
                        </div>
                        <h3 style="font-size: 18px;"><a>INALÁMBRICO</a></h3>
                        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p> -->
                        <div class="number second-label">
                            <span>Internet por antena</span>
                        </div>
                        <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-6 clickable" (click)="planSelect(4)">
                    <div class="single-features" [ngClass]="{'active': planselect === 4}">
                        <div class="icon">
                            <i class='bx bx-crosshair'></i>
                        </div>
                        <h3 style="font-size: 18px;"><a>Seguridad</a></h3>
                        <div class="number">
                            <span>Con Inteligencia Artificial</span>
                        </div>
                        <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                    </div>
                </div>

            </div>

            <!-- PLANES estandar -->
            <div *ngIf="planselect === 1" [@inOutAnimation]>
                <ul class="tabs">
                    <li><a (click)="type = 1" [ngClass]="{'active': type === 1}">SIN TV</a></li>
                    <li><a (click)="type = 2" [ngClass]="{'active': type === 2}">CON TV</a></li>
                </ul>

                <div class="tab_content">
                    <div class="tabs_item">
                        <div class="row">
                            <div class="col-lg-4 col-md-6" *ngFor="let item of planes">
                                <div class="single-pricing-box">
                                    <div class="pricing-header">
                                        <h3>{{item.nombre}}{{type===2?'+TV':''}}</h3>
                                    </div>

                                    <div class="price">
                                        ${{ getPrice(item.precio) }}
                                    </div>

                                    <p style="padding: 10px;">{{item.descripcion}}</p>

                                    <div class="pricing-btn">
                                        <a href="https://calculadora.onpw.ar" class="default-btn">Ver Cobertura</a>
                                    </div>

                                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FIN PLANES estandar -->
            <!-- PLANES PRO FIBRA -->
            <div *ngIf="planselect === 3" [@inOutAnimation]>
                <ul class="tabs">
                    <li><a (click)="type = 1" [ngClass]="{'active': type === 1}">SIN TV</a></li>
                    <li><a (click)="type = 2" [ngClass]="{'active': type === 2}">CON TV</a></li>
                </ul>
                <div class="tab_content">
                    <div class="tabs_item">
                        <div class="row d-flex justify-content-between">
                            <div class="col-lg-4 col-md-4" *ngFor="let item of planesProFibra">
                                <div class="headplus" *ngIf="item.recomendado">{{item.recomendadoMsg}}</div>
                                <div class="single-pricing-box" [ngClass]="{'enhance': item.recomendado}">
                                    <div class="pricing-header">
                                        <h3>FIBRA <b>{{item.nombre}}{{type===2?'+TV':''}}</b></h3>
                                    </div>
                                    <div *ngIf="item.promo" class="price-f price-small-centered"> 
                                        Descuento Fijo: ${{item.promoAmount}}
                                    </div>
                                    <div class="price price-f close-bottom row d-flex justify-content-center" *ngIf="item.promo">
                                        <div class="">
                                            ${{ getPrice(item.precio-item.promoAmount) }}    
                                        </div>
                                        <div class="price-small">
                                            Promo por <br>{{item.promoTime}} Meses
                                        </div>
                                    </div>
                                    <div *ngIf="item.promo" class="price-f price-old price-small-centered"> 
                                        Precio de lista: ${{ getPrice(item.precio) }}
                                    </div>

                                    <!-- TODO precios sin promo  -->
                                    <div class="price" *ngIf="!item.promo">
                                        ${{ getPrice(item.precio) }}
                                    </div>

                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-download"></i></div>
                                        <div class="feat-desc">Misma velocidad de bajada y de subida</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-wifi"></i></div>
                                        <div class="feat-desc">Router WiFi Doble Banda</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center" *ngIf="item.promo">
                                        <div class="feat-icon"><i class="bx bxs-offer"></i></div>
                                        <div class="feat-desc">Promoción con Descuento por {{item.promoTime}} meses</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-alarm"></i></div>
                                        <div class="feat-desc">Soporte técnico 24hs en días hábiles</div>
                                    </div>

                                    <div class="pricing-btn">
                                        <a href="https://wa.me/+5492645854228" class="default-btn">Ver Cobertura</a>
                                    </div>

                                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row d-flex justify-content-between">
                            <div class="col-lg-12 col-md-12">
                                <div class="single-pricing-box">
                                    <div class="pricing-header">
                                        <h3>Además, ¡Octubre instalación bonificada! 😎</h3>
                                    </div>
                                    <!-- <div class="price-f price-small-centered"> 
                                        Descuento Fijo:
                                    </div>
                                    <div class="price price-f close-bottom row d-flex justify-content-center">
                                        <div class="">asd   
                                        </div>
                                        <div class="price-small">
                                            Promo por <br>asd Meses
                                        </div>
                                    </div>
                                    <div class="price-f price-old price-small-centered"> 
                                        Precio de lista: asd
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-download"></i></div>
                                        <div class="feat-desc">Misma velocidad de bajada y de subida</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-wifi"></i></div>
                                        <div class="feat-desc">Router WiFi Doble Banda</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bxs-offer"></i></div>
                                        <div class="feat-desc">Promoción con Descuento por meses</div>
                                    </div>
                                    <div class="features-item row d-flex justify-content-center">
                                        <div class="feat-icon"><i class="bx bx-alarm"></i></div>
                                        <div class="feat-desc">Soporte técnico 24hs en días hábiles</div>
                                    </div>

                                    <div class="pricing-btn">
                                        <a href="https://wa.me/+5492645854228" class="default-btn">Ver Cobertura</a>
                                    </div>

                                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- FIN PLANES PRO FIBRA-->
             <!-- PLANES SOLO TV -->
             <div *ngIf="planselect === 4" [@inOutAnimation]>
                <div class="container">
                    <div class="row align-items-center" *ngIf="!camerasPlanActive ; else elseBlock">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-content">
                                <span>ONPOWER Security</span>
                                <h3>Sistema de cámaras con inteligencia artificial</h3>
                                <strong>Es un avanzado sistema de videovigilancia que utiliza inteligencia artificial para detectar personas en tiempo real.</strong>
                                <p>Te brindamos la cámara en comodato, para que puedas disfrutar de este servicio sin preocuparte por la inversión inicial. Con cámaras de alta definición y una aplicación móvil intuitiva, podrás monitorear tu hogar desde cualquier lugar y recibir alertas instantáneas en caso de actividad sospechosa.<br>- 24Hs de grabación en la nube y Soporte tecnico incluídos</p>
                                <div class="about-btn">
                                    <a href="https://wa.me/+5492645854228" class="default-btn">Más Información</a>
                                </div>
                            </div>
                            <div class="single-features-box security-feature" (click)="openCloseCalculator()">
                                Conoce nuestra calculadora de planes 😁
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-image">
                                <img src="assets/img/onpower-security.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                    <ng-template #elseBlock>
                        <app-security-calculator class="security-container flex-container"></app-security-calculator>
                    </ng-template>
                </div>
            </div>
            <!-- FIN PLANES SOLO TV -->
        </div>
    </div>
</section>
<!-- End Pricing -->
<!-- Main Banner -->
<div class="main-banner">
    <div class="main-banner-item item-four">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content">
                                <h1 style="text-transform:none!important;">Con el corazón puesto en la atención</h1>
                                <p class="subtitle">Soporte técnico: Atención las 24hs en días hábiles, Sábados y Domingos de 9 a 24hs, Feriados 9 a 18hs</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/banner/banner-image-4.jpg" style="max-height: 700px" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Subscribe -->
<!-- <section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <h2>¿Tenés un problema técnico?</h2>
            <span>¡En ONPOWER, los problemas técnicos no son un obstáculo! Te damos una solución en menos de 24 horas.</span>
        </div>
    </div>
</section> -->
<!-- End Subscribe -->
<!-- Features -->
<section class="blog-area pb-70">
    <div class="container">
        <div class="section-title text-width">
            <!-- <span>Care Features</span> -->
            <h2 style="margin-top: 50px;">La mejor tecnología a tu servicio</h2>
            <div class="bar"></div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-image-warp">
                    <img src="assets/img/home23/onpw-tecnologia.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-1">
                            <div class="icon">
                                <i class="flaticon-promotion"></i>
                            </div>
                            <h3><a href="http://clientes.onpower.com.ar/">Panel de clientes</a></h3>
                            <p>Podrás gestionar información reelevante de tu plan contratado, realizar pagos, ver tus consumos diarios, mensuales e instantáneos y más!</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-2">
                            <div class="icon">
                                <i class="flaticon-network"></i>
                            </div>
                            <h3><a href="http://status.onpower.com.ar/">Estado de los servicios</a></h3>
                            <p>Podés visualizar el estado de nuestros servicios (incluyendo servicio de internet) en tiempo real.</p>
                        </div>
                    </div>


                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-3">
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>
                            <h3><a href="https://onpower.freshdesk.com/support/solutions">Base de conocimientos</a></h3>
                            <p>¿Reseteaste tu router? ¿Desconectaste algo sin querer? Ingresá a nuestra base de conocimientos para que lo puedas solucionar de inmediato</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="single-features-box top-4" (click)="openwhatsapp()">
                            <div class="icon">
                                <i class="flaticon-speed"></i>
                            </div>
                            <h3><a href="https://wa.me/+5492645854228">Soporte técnico</a></h3>
                            <p>
                                <b>Horarios: </b><br>
                                Dias hábiles las 24hs<br> 
                                Sábado y Domingo: 9hs a 24hs<br> 
                                Feriados: 9hs a 18hs</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Features -->
<!-- About -->
<section class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>ONPOWER TV</span>
                    <h3>Descubrí una nueva forma de ver televisión</h3>
                    <div class="bar"></div>
                    <strong>Con ONPOWER TV, podrás disfrutar de la mejor tecnología para una experiencia televisiva única, disponible para TV Samsung, Android, Chromecast, Apple y WEB.</strong>
                    <p>También podés contratar HBO Max y Paramount+ en pesos junto a tu factura</p>
                    <div class="about-btn">
                        <a routerLink="/tv" class="default-btn">Más Información</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/onpowertv.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About Area -->
<!-- Overview -->
<!-- <section class="overview-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="overview-content">
                    <span>Confía en nosotros</span>
                    <h3>¡Unicos en resolver en menos de un día!</h3>
                    <p>Si tu servicio tiene problemas, te los resolvemos en un día</p>
                    <div class="overview-btn">
                        <a routerLink="/consorcios" class="default-btn">Leer Mas</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="overview-image">
                    <img src="assets/img/overview.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>  -->
<!-- End Overview -->
<!-- Contact Info Area -->
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Contactanos</span>
            <h2>¿Alguna consulta?</h2>
            <div class="bar"></div>
        </div>
        <div class="row d-flex justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box" (click)="openwhatsapp()">
                    <div class="icon">
                        <i class='bx bxl-whatsapp'></i>
                    </div>
                    <h3>Whatsapp</h3>
                    <p><a href="https://wa.me/+5492645854228" target="_blank">264 585 4228</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class='bx bxs-phone-call'></i>
                    </div>
                    <h3>Llamadas</h3>
                    <p><a href="tel:08003452545">0800 345 2545</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info -->
<a href="https://wa.me/+5492645854228" class="float" target="_blank">
    <i class="bx bxl-whatsapp my-float"></i>
</a>

