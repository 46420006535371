<div class="security-container flex-container" [ngClass]="{'hover': true}" *ngIf="currentNode">
    <div class="header flex-container" *ngIf="currentNode.title != null; else elseBlock">
        <div class="title-subtitle flex-container">
            <span class="title">{{currentNode.title}}</span>
            <span class="subtitle">{{currentNode.subtitle}}</span>
        </div>
        <span class="label">{{currentNode.label}}</span>
    </div>
    <ng-template #elseBlock>
        <h2>Servicio {{currentNode.plan.name}}</h2>

        <div class="final-plan-details flex-container" *ngIf="finalPlan">
            <li class="item"> Instalacion:  ${{finalPlan.facility}} </li>
            <li class="item" *ngIf="currentNode.plan.basePrice"> Servicio Base: ${{finalPlan.basePrice}} </li>
            <li class="item" *ngIf="currentNode.plan.additionalPrice"> Servicio Adicional: ${{finalPlan.additionalPrice}} </li>
            <li class="item"> Costo total: ${{finalPlan.price}}</li>
        </div>
    </ng-template>

    <div class="buttons flex-container" *ngIf="currentNode.buttons != null">
        <button class="default-btn btn-alt custom-width" (click)="iterateOnTree(0)">{{currentNode.buttons[0]}}</button>
        <button class="default-btn btn-alt custom-width" (click)="iterateOnTree(1)" >{{currentNode.buttons[1]}}</button>
        <button  class="default-btn background-violet" *ngIf="road.length > 0" (click) = 'backToPreviousNode()'>Volver</button>
    </div>

    <button class="default-btn btn-alt reset-button" *ngIf="finalPlan" (click) = 'resetCalculator()'>Reiniciar calculadora</button>
</div>
