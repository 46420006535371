import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  constructor(private http: HttpClient) { }

  public getPlanesPro(): Observable<any>{
    return this.http.get('https://calculadora.onpw.ar/assets/planesconsorcios.json');
  }
  public getPlanesProFibra(): Observable<any>{
    return this.http.get('https://calculadora.onpw.ar/assets/planesfibra.json');
  }

  public getPlanes(): Observable<any>{
    return this.http.get('https://calculadora.onpw.ar/assets/planesinalambricos.json');
  }

  public getPrecios(): Observable<any>{
    return this.http.get('https://calculadora.onpw.ar/assets/precios.json');
  }
  public getConsorcios(): Observable<any>{
    return this.http.get('https://calculadora.onpw.ar/assets/consorcios.json');
  }
}
