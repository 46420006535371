import { Component, Inject, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { SecurityCalculatorComponent } from "../../common/security-calculator/security-calculator.component";

@Component({
  selector: 'app-home-five',
  templateUrl: './home-five.component.html',
  styleUrls: ['./home-five.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
                    style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: '*', opacity: 1 }),
            animate('1s ease-in',
            style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ],
})
export class HomeFiveComponent implements OnInit {
  planselect = 0;
  planes = [];
  planesPro = [];
  precioTV: any;
  type = 1;
  public camerasPlanActive: boolean;
  planesProFibra: any;
  constructor(private svc: DataService, private route: ActivatedRoute) { 
    this.camerasPlanActive = false
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let section = params['section'];
      if(section){
        this.planSelect(Number.parseInt(section));
      }else{
        setTimeout(()=>{
          this.planSelect(3);
        },1000)
      }
    })
    this.svc.getPlanes().subscribe(res => {
        this.planes = res;
    });
    this.svc.getPlanesPro().subscribe(res => {
        this.planesPro = res;
    });
    this.svc.getPlanesProFibra().subscribe(res => {
        this.planesProFibra = res;
    });
    this.svc.getPrecios().subscribe(res => {
        this.precioTV = res.find(val => {
            if(val.nombre === 'Tv'){
                return true;
            }
            return false;
        }).precio;
    })
    
  }

  planSelect(option): void{
    if(this.planselect === option){
        this.planselect = 0;
    }else{
        this.planselect = option;
    }
  }
  openwhatsapp(){
    location.href= 'https://wa.me/+5492645854228';
  };
  getPrice(prc): any{
      if(this.type === 2){
        return Number.parseFloat(prc) + Number.parseFloat(this.precioTV);
      }else{
        return prc;
      }
  }

  openCloseCalculator(){
    this.camerasPlanActive = !this.camerasPlanActive
  }
}
