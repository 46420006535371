<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Forgot Password</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Forgot Password</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Forgot Password -->
<section class="forgot-password-area ptb-100">
    <div class="container">
        <div class="forgot-password-form">
            <div class="forgot-password-in-title">
                <h3>Forgot Your Password</h3>
            </div>
                
            <form>
                <div class="form-group">
                    <input type="text" class="form-control" placeholder="Enter Your Email">
                </div>

                <div class="send-btn">
                    <button type="submit" class="default-btn">Submit Now</button>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Forgot Password -->