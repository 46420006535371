<!-- Navbar -->
<div class="navbar-area">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo.png" class="logoimg" alt="image"></a>
                    <a href="https://clientes.onpower.com.ar" class="default-btn" style="z-index: 9999; display: inline-block; float:right; margin-right: 50px; padding: 10px 15px; margin-top: 5px;"><i style="font-size:25px; display: inline-block; vertical-align: middle;" class='bx bx-home-circle'></i><span style="display: inline-block; vertical-align: middle;">Clientes</span></a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img class="logoimg" src="assets/img/logo.png" alt="image"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Inicio</a>
                        </li>
                        <li class="nav-item">
                            <a routerLink="/tv" routerLinkActive="active" class="nav-link">ONPOWER TV</a>
                        </li>
                        
                        <!-- <li class="nav-item">
                            <a routerLink="/team-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contacto</a>
                        </li> -->
                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <div class="option-item" style="margin-right: 10px;">
                            <a href="https://tv.onpower.com.ar" class="default-btn btn-alt">Ver TV</a>
                        </div>
                    </div>
                    <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <a href="https://clientes.onpower.com.ar" class="default-btn">Acceso Clientes</a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>
<!-- End Navbar -->
