<!-- Main Banner -->
<div class="main-banner">
    <div class="main-banner-item">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content">
                                <h1>We Move The  Digital Industrial Forward</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <div class="banner-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/banner/banner-image-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner-shape">
        <div class="shape-1"><img src="assets/img/banner/banner-shape-1.png" alt="image"></div>
        <div class="shape-2"><img src="assets/img/banner/banner-shape-2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/banner/banner-shape-3.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner -->

<!-- Features -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-width">
            <span>Care Features</span>
            <h2>Create Awesome Service With Our Tools</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3><a routerLink="/services-details">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>1</span>
                    </div>
                    <div class="shape">
                        <img src="assets/img/features-shape/features-shape-1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3><a routerLink="/services-details">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>2</span>
                    </div>
                    <div class="shape">
                        <img src="assets/img/features-shape/features-shape-1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3><a routerLink="/services-details">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>3</span>
                    </div>
                    <div class="shape">
                        <img src="assets/img/features-shape/features-shape-1.png" alt="image">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>4</span>
                    </div>
                    <div class="shape">
                        <img src="assets/img/features-shape/features-shape-1.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- About -->
<section class="about-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about/about-1.png" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="about-btn">
                        <a routerLink="/about-1" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About Area -->

<!-- Services -->
<section class="services-area bg-fff5ee pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Services we provided</span>
            <h2>Our Digital Marketing Services</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-1.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Website Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-2.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Digital Media & PPC</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-3.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-4.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Content Marketing Services</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-5.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Competitor Research</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="image">
                        <a routerLink="/services-details">
                            <img src="assets/img/services/services-6.png" alt="image">
                        </a>
                    </div>
                    <h3><a routerLink="/services-details">Software Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- Digital Experience -->
<section class="digital-experience-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Digital Experience</span>
            <h2>Outstanding Digital Experience</h2>
            <div class="bar"></div>
        </div>

        <div class="tab digital-experience-tab">
            <ul class="tabs">
                <li>
                    <a href="#">
                        <span>
                            <i class="flaticon-analysis"></i>
                            Real-Time Analytics
                        </span>
                    </a>
                </li>
                <li class="bg-5cbd12">
                    <a href="#">
                        <span>
                            <i class="flaticon-profit"></i>
                            Pay-Per-Click
                        </span>
                    </a>
                </li>
                <li class="bg-e2851b">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing"></i>
                            Online Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-04b893">
                    <a href="#">
                        <span>
                            <i class="flaticon-email-marketing"></i>
                            Email Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-785eda">
                    <a href="#">
                        <span>
                            <i class="flaticon-network"></i>
                            Social Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-d0465a">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing-2"></i>
                            Digital Marketing
                        </span>
                    </a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Real-Time Analytics</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Pay-Per-Click</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Online Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Email Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Social Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Digital Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-6.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Digital Experience -->

<!-- Fun Facts -->
<section class="fun-facts-area pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-checked"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="850">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Fun Facts -->

<!-- Portfolio -->
<section class="portfolio-area bg-fff5ee pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Portfolio Showcase</span>
            <h2>Our Impressive Portfolio</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-1.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Creative Web Design</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-2.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-3.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Application</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-4.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Dashboard App</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-5.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">App Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-6.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Digital Agency</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-7.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Website Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-8.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Logo & Branding</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-9.jpg" alt="image">
                    </div>
                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Mobile App Development</a></h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Portfolio -->

<!-- Testimonial -->
<section class="testimonial-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-6">
                <div class="testimonial-content">
                    <span>Testimonials</span>
                    <h2>Our Client’s Review</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Morris Jacket</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-two"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Mahindra jhon</h4>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-three"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Lee Munroe</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonial -->

<!-- Blog -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h2>Latest News From Blog</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March, 2020</span>
                        <h3><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 March, 2020</span>
                        <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>28 March, 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Blog -->

<!-- Partner -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->