import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DesicionNode } from 'src/app/models/desicion-node/desicion-node';
import { SecurityPlans } from 'src/app/models/desicion-node/security-plans/security-plans';

@Injectable({
  providedIn: 'root'
})
export class DesicionNodeService {
  jsonUrl = 'assets/plans/security-tree.json'
  constructor(private http : HttpClient) { }

  getSecurityTree() : Observable<DesicionNode> {
    return this.http.get<DesicionNode>(this.jsonUrl)
  }
  
  getAllPlans(plan) : Observable<SecurityPlans>{
    const url = 'assets/plans/security-plans.json'
    return this.http.get<SecurityPlans>(url)
  }
}
