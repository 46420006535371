import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-features-style-one',
  templateUrl: './features-style-one.component.html',
  styleUrls: ['./features-style-one.component.scss']
})
export class FeaturesStyleOneComponent implements OnInit {
  consorcios: any = [];

  constructor(private svc: DataService) { }

  ngOnInit(): void {
    this.svc.getConsorcios().subscribe(ccios => {
        this.consorcios = ccios;
    });
  }

}
