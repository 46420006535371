import { Component, OnInit } from '@angular/core';
import { DesicionNode } from 'src/app/models/desicion-node/desicion-node';
import { DesicionNodeService } from 'src/app/services/desicion-node/desicion-node.service';

@Component({
  selector: 'app-security-calculator',
  templateUrl: './security-calculator.component.html',
  styleUrls: ['./security-calculator.component.scss']
})
export class SecurityCalculatorComponent implements OnInit {

  desicionTree: DesicionNode;
  currentNode: DesicionNode;
  previousNode: DesicionNode;
  finalPlan;
  road: any[] = [];
  currentPlan;
  constructor(private securityService : DesicionNodeService) { }

  ngOnInit(): void {
    this.subscribeToSecurityService()
  }

  subscribeToSecurityService(){
    this.securityService.getSecurityTree().subscribe((data : DesicionNode) => {
      this.desicionTree = data;
      this.currentNode = data;
    })
  }

  iterateOnTree(answer : number){
    this.road.push(this.currentNode);
    this.currentNode = this.currentNode.nodes[answer];
    this.getPlanInfo()
  }

  backToPreviousNode(): void {
    // Pila vacia, vuelve al nodo raiz
    if (this.road.length === 0) {
      this.currentNode = this.desicionTree;
    } else {
      // Vuelve al nodo anterior
      this.currentNode = this.road[this.road.length - 1];
      this.road.pop()
    }

  }
  
  getPlanInfo(){
    if(this.currentNode?.plan != undefined){
      const currentChoice = this.currentNode.plan
      this.securityService.getAllPlans(this.currentNode.plan).subscribe((plan : any) => {
        let name = currentChoice.name.split(' ')
        let planRecommended = plan.plans.find((plan) => plan.planName == name[0] || plan.planName[1])
        let sum = planRecommended.facility;

        if(currentChoice.basePrice){
          sum += planRecommended.basePrice
        }else if(currentChoice.additionalPrice){
          sum += planRecommended.additionalPrice
        }
        this.finalPlan = {
          name: planRecommended.planName,
          basePrice: planRecommended.basePrice,
          additionalPrice: planRecommended.additionalPrice,
          facility: planRecommended.facility,
          price: sum
        }        
      })
    }
  }

  resetCalculator(): void {
    this.currentNode = this.road[0]
    this.road = []
    this.finalPlan = null;
  }
  
}
