import { Component } from '@angular/core';

@Component({
  selector: 'app-shop-list-one',
  templateUrl: './shop-list-one.component.html',
  styleUrls: ['./shop-list-one.component.scss']
})
export class ShopListOneComponent{

  constructor() { }
  filter = 0;
  canales = [{
		name: "Aliento Vision",
		icon: "LIBRE/alientovision.png",
		category: 6
	},
	{
		name: "Argentinisima",
		icon: "LIBRE/argentinisima.png",
		category: 1
	},
  {
		name: "Canal 26",
		icon: "EXTRAS/26.png",
		category: 1
	},
	{
		name: "El Nueve",
		icon: "EXTRAS/nueve.png",
		category: 1
	},
	{
		name: "Canal Luz",
		icon: "LIBRE/canalluz.png",
		category: 6
	},
	{
		name: "Cine.ar",
		icon: "LIBRE/cinear.png",
		category: 5
	},
	{
		name: "Ewtn",
		icon: "LIBRE/ewtn.png",
		category: 6
	},
	{
		name: "Hispan TV",
		icon: "LIBRE/hispantv.png",
		category: 6
	},
	{
		name: "IP",
		icon: "LIBRE/ip.png",
		category: 6
	},
	{
		name: "NHK",
		icon: "LIBRE/nhk.png",
		category: 6
	},
	{
		name: "Construir TV",
		icon: "LIBRE/construirtv.png",
		category: 6
	},
	{
		name: "RT",
		icon: "LIBRE/rt.png",
		category: 6
	},
	{
		name: "Telesur",
		icon: "LIBRE/telesur.png",
		category: 6
	},
	{
		name: "Tv Publica",
		icon: "LIBRE/tvp.png",
		category: 1
	},
	{
		name: "Canal 6",
		icon: "FEDERALES/6.png",
		category: 1
	},
	{
		name: "24/7",
		icon: "FEDERALES/247-canal.png",
		category: 1
	}, {
		name: "BVC",
		icon: "FEDERALES/bvc.png",
		category: 1
	},
	{
		name: "Canal 10 Pergamino",
		icon: "FEDERALES/C10-pergamino.png",
		category: 1
	}, {
		name: "Canal 12 Trenque Launquen",
		icon: "FEDERALES/C12-TrenqueLaunquen.png",
		category: 1
	},
	{
		name: "Canal 19 - La Pampa",
		icon: "FEDERALES/C19-la-pampa.png",
		category: 1
	}, {
		name: "Chubut",
		icon: "FEDERALES/chubut.png",
		category: 1
	},
	{
		name: "El Once Paraná",
		icon: "FEDERALES/elonce-parana.png",
		category: 1
	}, {
		name: "Remanso",
		icon: "FEDERALES/remanso.png",
		category: 1
	},
	{
		name: "RTN",
		icon: "FEDERALES/rtn.png",
		category: 1
	}, {
		name: "T5 Litoral",
		icon: "FEDERALES/t5-litoral.png",
		category: 1
	},
	{
		name: "UN MDP",
		icon: "FEDERALES/UNMDP.png",
		category: 1
	},
	{
		name: 'A24',
		icon: 'FULL/a24.png',
		category: 1
	},
	{
		name: 'AE',
		icon: 'FULL/ae.png',
		category: 4
	},
	{
		name: 'AMC',
		icon: 'FULL/amc.png',
		category: 5
	},
	{
		name: 'América',
		icon: 'FULL/america.png',
		category: 1
	},
	{
		name: 'Animal Planet',
		icon: 'FULL/animal-planet.png',
		category: 4
	},
	{
		name: 'AXN',
		icon: 'FULL/axn.png',
		category: 5
	},
	{
		name: 'Baby TV',
		icon: 'FULL/babytv.png',
		category: 3
	},
	{
		name: 'C5N',
		icon: 'FULL/c5n.png',
		category: 1
	},
	{
		name: 'Canal de la Musica',
		icon: 'FULL/canaldelamusica.png',
		category: 6
	},
	{
		name: 'Cartoon Network',
		icon: 'FULL/cartoon-network.png',
		category: 3
	},
	{
		name: 'Cartoonito',
		icon: 'FULL/cartoonito.png',
		category: 3
	},
	{
		name: 'Cinecanal',
		icon: 'FULL/cinecanal.png',
		category: 5
	},
	{
		name: 'Cinemax',
		icon: 'FULL/cinemax.png',
		category: 5
	},
	{
		name: 'CN23',
		icon: 'FULL/cn23.png',
		category: 1
	},
	{
		name: 'CNN Internacional',
		icon: 'FULL/cnn-international.png',
		category: 6
	},
	{
		name: 'CNN',
		icon: 'FULL/cnn.png',
		category: 6
	},
	{
		name: 'Comedy Central',
		icon: 'FULL/comedycentral.png',
		category: 6
	},
	{
		name: 'Cronica HD',
		icon: 'FULL/cronicahd.png',
		category: 1
	},
	{
		name: 'ID Discovery',
		icon: 'FULL/discovery id.png',
		category: 4
	},
	{
		name: 'Discovery Turbo',
		icon: 'FULL/discovery turbo.png',
		category: 4
	},
	{
		name: 'Discovery',
		icon: 'FULL/discovery-channel.png',
		category: 4
	},
	{
		name: 'Discovery Kids',
		icon: 'FULL/discovery-kids.png',
		category: 3
	},
	{
		name: 'Discovery Science',
		icon: 'FULL/discovery-science.png',
		category: 4
	},
	{
		name: 'Discovery Theater',
		icon: 'FULL/discovery-theater.png',
		category: 4
	},
	{
		name: 'Discovery World',
		icon: 'FULL/discoveryworld.png',
		category: 4
	},
	{
		name: 'Disney Junior',
		icon: 'FULL/disney junior.png',
		category: 3
	},
	{
		name: 'Disney Channel',
		icon: 'FULL/disney.png',
		category: 3
	},
	{
		name: 'E',
		icon: 'FULL/e.png',
		category: 6
	},
	{
		name: 'El Trece',
		icon: 'FULL/eltrece.png',
		category: 1
	},
  {
    name: 'ESPN',
    icon: 'FULL/espn.png',
    category: 2
  },
	{
		name: 'ESPN 2',
		icon: 'FULL/espn-2.png',
		category: 2
	},
	{
		name: 'ESPN 3',
		icon: 'FULL/espn-3.png',
		category: 2
	},
	{
		name: 'ESPN Extra',
		icon: 'FULL/espn-extra.png',
		category: 2
	},
	{
		name: 'Europa Europa',
		icon: 'FULL/europaeuropa.png',
		category: 5
	},
	{
		name: 'Film&Arts',
		icon: 'FULL/film-arts.png',
		category: 5
	},
	{
		name: 'Food Network',
		icon: 'FULL/foodnetwork.png',
		category: 6
	},
	{
		name: 'Fox Sports',
		icon: 'FULL/foxsports.png',
		category: 2
	},
	{
		name: 'Fox Sports 2',
		icon: 'FULL/foxsports2.png',
		category: 2
	},
	{
		name: 'Fox Sports 3',
		icon: 'FULL/foxsports3.png',
		category: 2
	},
	{
		name: 'FX',
		icon: 'FULL/fx.png',
		category: 5
	},
	{
		name: 'Garage TV',
		icon: 'FULL/garagetv.png',
		category: 5
	},
	{
		name: 'Glitz',
		icon: 'FULL/glitz.png',
		category: 5
	},
	{
		name: 'Gourmet',
		icon: 'FULL/gourmet.png',
		category: 6
	},
	{
		name: 'HGTV',
		icon: 'FULL/hgtv.png',
		category: 4
	},
	{
		name: 'History',
		icon: 'FULL/history.png',
		category: 4
	},
	{
		name: 'History 2',
		icon: 'FULL/history2.png',
		category: 4
	},
	{
		name: 'HTV',
		icon: 'FULL/htv.png',
		category: 6
	},
	{
		name: 'Discovery H&H',
		icon: 'FULL/hyh-discovery.png',
		category: 4
	},
	{
		name: 'ISAT',
		icon: 'FULL/isat.png',
		category: 5
	},
	{
		name: 'IVC',
		icon: 'FULL/ivc.png',
		category: 5
	},
	{
		name: 'Lifetime',
		icon: 'FULL/lifetime.png',
		category: 6
	},
	{
		name: 'LN+',
		icon: 'FULL/ln+.png',
		category: 1
	},
	{
		name: 'Mas Chic',
		icon: 'FULL/maschic.png',
		category: 6
	},
	{
		name: 'MTV',
		icon: 'FULL/mtv.png',
		category: 6
	},
	{
		name: 'Much',
		icon: 'FULL/much.png',
		category: 6
	},
	{
		name: 'NATGEO',
		icon: 'FULL/natgeo.png',
		category: 4
	},
	{
		name: 'NBC Golf',
		icon: 'FULL/nbcgolf.png',
		category: 2
	},
	{
		name: 'Nick Jr.',
		icon: 'FULL/nick-jr.png',
		category: 3
	},
	{
		name: 'Nickelodeon',
		icon: 'FULL/nickelodeon.png',
		category: 3
	},
	{
		name: 'Rural',
		icon: 'FULL/rural.png',
		category: 6
	},
	{
		name: 'SONY',
		icon: 'FULL/sony.png',
		category: 5
	},
	{
		name: 'Space',
		icon: 'FULL/space.png',
		category: 5
	},
	{
		name: 'Star Channel',
		icon: 'FULL/star-channel.png',
		category: 5
	},
	{
		name: 'Studio Universal',
		icon: 'FULL/studio universal.png',
		category: 5
	},
	{
		name: 'SYFY',
		icon: 'FULL/syfy.png',
		category: 4
	},
	{
		name: 'TBS',
		icon: 'FULL/tbs.png',
		category: 6
	},
	{
		name: 'TCM',
		icon: 'FULL/tcm.png',
		category: 5
	},
	{
		name: 'TD',
		icon: 'FULL/td.png',
		category: 6
	},
	{
		name: 'Telefe',
		icon: 'FULL/telefe.png',
		category: 1
	},
	{
		name: 'Telemundo',
		icon: 'FULL/telemundo internacional.png',
		category: 6
	},
	{
		name: 'TLC',
		icon: 'FULL/tlc.png',
		category: 4
	},
	{
		name: 'TN',
		icon: 'FULL/tn.png',
		category: 1
	},
	{
		name: 'TNT Series',
		icon: 'FULL/tnt series.png',
		category: 5
	},
	{
		name: 'TNT',
		icon: 'FULL/tnt.png',
		category: 5
	},
	{
		name: 'Tooncast',
		icon: 'FULL/tooncast.png',
		category: 3
	},
	{
		name: 'tru TV',
		icon: 'FULL/trutv.png',
		category: 6
	},
	{
		name: 'Universal',
		icon: 'FULL/universal.png',
		category: 5
	},
	{
		name: 'WarnerBrothers TV',
		icon: 'FULL/wbtv.png',
		category: 5
	},
	{
		name: 'Del sur TV',
		icon: 'LOCALES/delsurtv.jpeg',
		category: 7
	},{
		name: 'Canal 4',
		icon: 'LOCALES/canalcuatro.webp',
		category: 7
	},{
		name: 'Guanaco Play',
		icon: 'LOCALES/guanacoplay.jpg',
		category: 7
	},{
		name: 'XAMA',
		icon: 'LOCALES/xama.png',
		category: 7
	},{
		name: 'Canal 13 San Juan TV',
		icon: 'LOCALES/canal13.png',
		category: 7
	},{
		name: 'Huarpe TV',
		icon: 'LOCALES/huarpetv.png',
		category: 7
	},{
		name: 'Telesol',
		icon: 'LOCALES/telesol.jpeg',
		category: 7
	},{
		name: 'AM1020',
		icon: 'LOCALES/am1020.png',
		category: 7
	},{
		name: 'ZONDA TV',
		icon: 'LOCALES/zondatv.jpg',
		category: 7
	}
];

adicionales = [{
	name: "Pack HBO",
	description: "Incluye APP HBO MAX y 9 Canales HD",
	channels: [
		{ name: 'HBO 2', icon: 'HBO/hbo-2.png', category: 99 },
		{ name: 'HBO Family', icon: 'HBO/hbo-family.png', category: 99 },
		{ name: 'HBO Mundi', icon: 'HBO/hbo-mundi.png', category: 99 },
		{ name: 'HBO Plus', icon: 'HBO/hbo-plus.png', category: 99 },
		{ name: 'HBO Pop', icon: 'HBO/hbo-pop.png', category: 99 },
		{ name: 'HBO Signature', icon: 'HBO/hbo-signature.png', category: 99 },
		{ name: 'HBO Xtreme', icon: 'HBO/hbo-xtreme.png', category: 99 },
		{ name: 'HBO', icon: 'HBO/hbo.png', category: 99 },
		{
			name: 'HBO MAX',
			icon: 'VOD/hbo-max.png',
			category: 100
		}
	]
},{
	name: "Pack Futbol",
	description: "Incluye ESPN Premium y TNT Sports",
	channels: [{
		name: 'ESPN Premium',
		icon: 'FUTBOL/espn-premium.png',
		category: 99
	  },
	  {
		name: 'TNT Sports',
		icon: 'FUTBOL/tntsports.png',
		category: 99
	  }]
},{
	name: "Paramount+",
	description: "Disfrutá de Paramount+ en todos tus dispositivos",
	channels: [{
		name: 'PARAMOUNT+',
		icon: 'VOD/paramount-plus.png',
		category: 100
	  }]
}]
 options = [
    {name: "San Juan", id:7},
    {name: "Todos", id:0},
    {name: "Nacionales", id:1},
    {name: "Deportes", id:2},
    {name: "Infantiles", id:3},
    {name: "Ciencia y Documentales", id:4},
    {name: "Peliculas y Series", id:5},
    {name: "Variedades", id:6}
  ]
  onChange(newValue) {
    debugger;
    console.log("test");
    this.filter = newValue;
    // ... do other stuff here ...
  }
  canalesActivos(canales, filtro){
    console.log(filtro);
    return canales.filter(canal => (filtro == 0 || canal.category == filtro ));
  }
  chShowdown(){
    if(this.filter!=0){
      return this.options.filter(item=>{
        if(item.id==this.filter){
          return true;
        }
      });
    }else{
      return this.options.filter(item=>{
        if(item.id!=0){
          return true;
        }
      });
    }
  }
}
