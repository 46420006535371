<!-- Footer -->
<div class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="image"></a>
                    </div>
                    <p>Somos proveedores de acceso a internet enfocados en calidad de servicio.</p>
                    <ul class="social">
                        <li><a href="https://www.facebook.com/onpowerAR" class="facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.instagram.com/onpower.ar/" class="pinterest" target="_blank"><i class='bx bxl-instagram-alt'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Enlaces</h3>

                    <ul class="quick-links">
                        <li><a href="https://clientes.onpower.com.ar/">Panel de clientes</a></li>
                        <li><a routerLink="/consorcios">Consorcios y Shoppings Adheridos</a></li>
                        <li><a href="https://onpower.freshdesk.com/support/solutions/folders/48000662387">Instructivo de pago</a></li>
                        <li><a href="http://status.onpower.com.ar/">Estado de los servicios</a></li>
                        <li><a href="https://onpower.freshdesk.com/support/home">Base de conocimientos</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>¡Contactanos!</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class='bx bxs-phone-call'></i>
                            <span>Teléfono</span>
                            <a href="tel:08003452545">0800 345 2545</a>
                        </li>
                        <li>
                            <i class='bx bxl-whatsapp'></i>
                            <span>Whatsapp</span>
                            <a href="https://wa.me/+5492645854228" target="_blank">264 585 4228</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright -->
<!-- <div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p><i class="far fa-copyright"></i> ONPOWER - 2020</p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/terms-of-service">Terms of Service</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>
<!-- End Go Top -->
