<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Contact Info Area -->
<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Get in Touch</span>
            <h2>Ready to Get Started?</h2>
            <div class="bar"></div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-email-1"></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:hello@rainz.com">hello&#64;rainz.com</a></p>
                    <p><a href="mailto:support@rainz.com">support&#64;rainz.com</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-pin"></i>
                    </div>
                    <h3>Location Here</h3>
                    <p><a href="https://www.google.com/maps/@24.9045273,91.8523559,15z" target="_blank">2750 Quadra Street Victoria Road, New York, Canada</a></p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="contact-info-box">
                    <div class="icon">
                        <i class="flaticon-call"></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:1234567890">+123 456 7890</a></p>
                    <p><a href="tel:2414524526">+241 452 4526</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info -->

<!-- Contact -->
<section class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Get in Touch</span>
            <h2>Ready to Get Started?</h2>
            <div class="bar"></div>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>First Name*</label>
                            <input type="text" name="name" id="name" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Last Name*</label>
                            <input type="text" name="name" id="name" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Your Email*</label>
                            <input type="email" name="email" id="email" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group">
                            <label>Your Phone*</label>
                            <input type="text" name="phone_number" id="phone_number" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Your Subject*</label>
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <label>Your Message*</label>
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">Send Message</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- End Contact -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form" data-toggle="validator">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->
