<!-- Page Banner -->
<div class="page-banner-area item-bg1">
    <div class="container">
        <div class="page-banner-content">
            <h2>Descubri una nueva<br> forma de ver televisión</h2>
            <p style="color:white;">Con nuestro nuevo servicio, podrás disfrutar de la mejor <br>tecnología y funcionalidades para una experiencia televisiva única.</p>
            <a href="https://wa.me/+5492645854228" class="default-btn custom">Quiero Contratar</a>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Products -->
<section class="product-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 result-count">
                <h2 class="main-title">Grilla de canales</h2>
            </div>
        </div>
        <div class="rainz-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <p>Mostrando <span class="count">{{canalesActivos(canales, filter).length}}</span> de {{canales.length}} Canales disponibles</p>
            </div>

            <div class="col-lg-6 col-md-6 ordering">
                <div class="select-box">
                    <label>Filtrar por:</label>
                    <select [(ngModel)]="filter" class="selectc">
                        <option *ngFor="let opt of options" value="{{opt.id}}">{{opt.name}}</option>
                    </select>
                </div>
            </div>
        </div>

        <div *ngFor="let chType of chShowdown()">
            <div class="row">
                <div class="col-lg-12 col-md-12 result-count">
                    <p class="channel-type">{{chType.name}}</p>
                </div>
            </div>
            <div class="row cntr">
                <div class="col-lg-1 col-md-3" *ngFor="let canal of canalesActivos(canales, chType.id)">
                    <div class="single-products-box">
                        <div class="products-image">
                            <img src="assets/img/tv/{{canal.icon}}" alt="image">
                        </div>
                        <div class="products-content">
                            <small>{{canal.name}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 result-count">
                <h2 class="main-title">Paquetes adicionales</h2>
            </div>
        </div>
        <div *ngFor="let chType of adicionales">
            <div class="row">
                <div class="col-lg-12 col-md-12 result-count">
                    <p class="channel-type"><b>{{chType.name}} </b> <small> {{chType.description}}</small></p>
                </div>
            </div>
            <div class="row cntr">
                <div class="col-lg-2 col-md-3" *ngFor="let canal of chType.channels">
                    <div class="single-products-box">
                        <div class="products-image">
                            <img src="assets/img/tv/{{canal.icon}}" alt="image">
                        </div>
                        <div class="products-content">
                            <small>{{canal.name}}</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Products -->