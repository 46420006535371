<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Contact -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Get in Touch</span>
            <h2>Ready to Get Started?</h2>
            <div class="bar"></div>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="contact-image">
                    <img src="assets/img/contact.png" alt="image">
                </div>
            </div>

            <div class="col-lg-7">
                <div class="contact-form-inner">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="general-contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Phone Number or Email Address</h3>
                <h2>
                    <a href="tel:+4444444444444444">+654 484 574 5475</a>
                    <span>OR</span>
                    <a href="mailto:hello@rainz.com">hello&#64;rainz.com</a>
                </h2>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<!-- End Contact -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form" data-toggle="validator">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->